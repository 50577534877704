// import React, { useState } from 'react';
// import { Link } from 'react-router-dom';
// import logo from '../../assets/logo.png';

// const Header = () => {
//   const [modalOpen, setModalOpen] = useState(false);

//   const toggleModal = () => {
//     setModalOpen(!modalOpen);
//   };

//   const closeModal = () => {
//     setModalOpen(false);
//   };

//   return (
//     <header className="header bg-white py-2 w-full z-50 shadow-md">
//       <div className="container mx-auto">
//         <div className="flex items-center justify-between">
//           <button className="nav-toggle lg:hidden text-3xl p-2" onClick={toggleModal}>
//             ☰
//           </button>
//           <div className="header-left flex items-center p-1">
//             <div className="location text-sm mr-1">Oroville, CA</div>
//             <div className="phone text-sm">
//               <i className="fa fa-phone mr-2"></i> <a className="text-blue-500 font-bold" href="tel:+15305335970">(530) 533-5970</a>
//             </div>
//           </div>
//         </div>
//         <div className="flex flex-col items-center">
//           <div className="logo-container">
//             <Link to="/">
//               <img src={logo} alt="CWI Floors" className="mx-auto" />
//               </Link>
//           </div>
//           <nav className="hidden lg:flex lg:items-center lg:space-x-3 lg:mb-0">
//             <ul className="flex flex-row lg:items-center lg:space-x-3">
//               <li><Link to="/products" className="block lg:inline-block lg:px-3 lg:py-1 hover:bg-blue-100 text-black no-underline rounded-md">Products</Link></li>
//               <li><Link to="/services" className="block lg:inline-block lg:px-3 lg:py-1 hover:bg-blue-100 text-black no-underline rounded-md">Services</Link></li>
//               <li><Link to="/flooring101" className="block lg:inline-block lg:px-3 lg:py-1 hover:bg-blue-100 text-black no-underline rounded-md">Flooring 101</Link></li>
//               <li><Link to="/reviews" className="block lg:inline-block lg:px-3 lg:py-1 hover:bg-blue-100 text-black no-underline rounded-md">Reviews</Link></li>
//               <li><Link to="/financing" className="block lg:inline-block lg:px-3 lg:py-1 hover:bg-blue-100 text-black no-underline rounded-md">Financing</Link></li>
//               <li><Link to="/commercial" className="block lg:inline-block lg:px-3 lg:py-1 hover:bg-blue-100 text-black no-underline rounded-md">Commercial Flooring</Link></li>
//               <li><Link to="/about" className="block lg:inline-block lg:px-3 lg:py-1 hover:bg-blue-100 text-black no-underline rounded-md">About Us</Link></li>
//               <li><Link to="/contact" className="block lg:inline-block lg:px-3 lg:py-1 hover:bg-blue-100 text-black no-underline rounded-md">Contact</Link></li>
//               <li><Link to="/getintouch" className="block lg:inline-block lg:px-3 lg:py-1 hover:bg-blue-100 text-black no-underline rounded-md">Get in touch →</Link></li>
//             </ul>
//           </nav>
//         </div>
//       </div>

//       {/* Modal for mobile view */}
//       {modalOpen && (
//         <div className="fixed inset-0 bg-gray-900 bg-opacity-75 z-50 flex items-center justify-center">
//           <div className="bg-white p-4 rounded-lg w-3/4 max-w-md relative">
//             <button className="absolute top-2 right-2 text-2xl" onClick={closeModal}>&times;</button>
//             <ul className="flex flex-col space-y-2">
//               <li><Link to="/products" className="block px-4 py-2 hover:bg-blue-100 text-black no-underline" onClick={closeModal}>Products</Link></li>
//               <li><Link to="/services" className="block px-4 py-2 hover:bg-blue-100 text-black no-underline" onClick={closeModal}>Services</Link></li>
//               <li><Link to="/flooring101" className="block px-4 py-2 hover:bg-blue-100 text-black no-underline" onClick={closeModal}>Flooring 101</Link></li>
//               <li><Link to="/reviews" className="block px-4 py-2 hover:bg-blue-100 text-black no-underline" onClick={closeModal}>Reviews</Link></li>
//               <li><Link to="/financing" className="block px-4 py-2 hover:bg-blue-100 text-black no-underline" onClick={closeModal}>Financing</Link></li>
//               <li><Link to="/commercial" className="block px-4 py-2 hover:bg-blue-100 text-black no-underline" onClick={closeModal}>Commercial Flooring</Link></li>
//               <li><Link to="/about" className="block px-4 py-2 hover:bg-blue-100 text-black no-underline" onClick={closeModal}>About Us</Link></li>
//               <li><Link to="/contact" className="block px-4 py-2 hover:bg-blue-100 text-black no-underline" onClick={closeModal}>Contact</Link></li>
//               <li><Link to="/getintouch" className="block px-4 py-2 hover:bg-blue-100 text-black no-underline" onClick={closeModal}>Get in touch →</Link></li>
//             </ul>
//           </div>
//         </div>
//       )}
//     </header>
//   );
// };

// export default Header;
import React, { useState } from 'react'
import myImage from '../../assets/logo.png';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import { FaLocationPin } from "react-icons/fa6";
import { FaWindowClose } from "react-icons/fa"
function Header() {
	const [menuOpen, setMenuOpen] = useState(false);

	const onToggleMenu = () => {
		setMenuOpen(true);
	};
	const onCloseMenu = () => {
		setMenuOpen(false); // Close the menu
	};

	return (
		<div>
			<div>
      <div className=' text-center pt-1.5 text-slate-900  text-md font-bold '><span>
					<p className='flex flex-row md:font-[10px] text-center items-center md:hidden justify-between  px-3 font-bold'>
						<span
							className='font-bold cursor-pointer flex flex-row'
							onClick={() => {
								window.open('https://www.google.com/maps/dir//Oroville+DMV+775+Mitchell+Ave+Oroville,+CA+95965/@39.5042196,-121.5651159,15z/data=!4m5!4m4!1m0!1m2!1m1!1s0x809cb44b5986d521:0x7068efa48e26029c', '_blank');
							}}
						>
						 <i className="bi bi-geo-alt text-salte-900 font-bold"> </i><span className="hidden md:flex text-slate-900 font-bold">Oroville, CA</span>
						</span>
						<a href="tel:+15305335970" className='text-slate-900 font-semibold ml-3 flex flex-row'>
							<i className="bi bi-telephone pl-5 text-slate-900 font-bold">
							</i>
							<span className="flex text-slate-900 font-bold">(530) 533-5970</span>
						</a>
					</p>
				</span>
				</div>
				<div class="container px-2">

					<div class="flex items-center md:justify-between justify-center pt-4 pb-2">

						<div class="hidden sm:flex sm:items-center">
							<p class="text-black text-sm font-semibold hover:text-purple-600 mr-4">Oroville, CA <br /> <a href="tel:+15305335970" className='text-black'> <i class="bi bi-telephone"> (530) 533-5970</i> </a></p>
						</div>

						<div className=" flex justify-center items-center " >
							<a href="/" class="flex justify-center items-center" ><img src={myImage} alt="Los Angeles" /> </a>

						</div>



						<div class="hidden sm:flex sm:items-center">
							<div class="text-black text-sm font-semibold border px-4 py-2 rounded-lg hover:text-purple-600 hover:border-purple-600"><a href='/products' className='text-black'> View Product </a></div>
						</div>


					</div>
					<div class="flex items-center lg:order-2 pb-2 px-3 z-[901]">
						<button onClick={onToggleMenu}
							name={menuOpen ? 'close' : 'menu'}
							className="text-3xl cursor-pointer md:hidden">
							<span class="sr-only">Open main menu</span>
							<svg class="w-6 h-6" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z" clip-rule="evenodd"></path></svg>
							<svg class="hidden w-6 h-6" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>

						</button>

					</div>



					<div
						className={`nav-links duration-500 md:static absolute text-slate-900 bg-white   left-0 ${menuOpen ? 'top-[9%] z-[900]' : 'top-[-100%]  items-center justify-content-center'
							} md:w-auto w-full flex  `} 
					>
						<ul className="flex md:flex-row flex-col md:items-center w-[100vw] md:w-auto  md:justify-content-center p-2 border-y-2 border-slate-900 ">

						<li className={`${menuOpen ? 'md:hidden' : 'md:hidden'} justify-end flex items-end`}>
								<button class="text-slate-900  font-semibold hover:text-purple-600 " onClick={onCloseMenu}>
								<FaWindowClose class="text-slate-900 text-[15px] ml-2" />
								</button>
							</li>
							<li className="nav-item hover:bg-slate-200 rounded-lg">
								<div className="btn-group dropend">
									<button
										type="button"
										className="btn  dropdown-toggle font-semibold text-slate-900 hover:bg-slate-200"
										data-bs-toggle="dropdown" 
										aria-expanded="false"
									>
										Products
									</button>
									<ul className="dropdown-menu">
										<li>
											<a href="/products" class=" dropdown-item hover:text-blue-900 hover:bg-slate-200 rounded-md text-slate-900 ">All Products</a>
										</li>
										<li>
											<a href="/products/carpet" class=" dropdown-item hover:text-blue-900 hover:bg-slate-200 rounded-md text-slate-900 ">Carpet</a>
										</li>
										<li>
											<a href="/products/hardwood" class=" dropdown-item hover:text-blue-900 hover:bg-slate-200 rounded-md text-slate-900 ">Hardwood</a>
										</li>
										<li>
											<a href="/products/laminate" class=" dropdown-item hover:text-blue-900 hover:bg-slate-200 rounded-md text-slate-900 ">Laminate</a>
										</li>
										<li>
											<a href="/products/luxury-vinyl" class=" dropdown-item hover:text-blue-900 hover:bg-slate-200 rounded-md text-slate-900 ">luxury Vinyl</a>
										</li>
										<li>
											<a href="/products/vinyl" class=" dropdown-item hover:text-blue-900 hover:bg-slate-200 rounded-md text-slate-900 ">Vinyl</a>
										</li>
										<li>
											<a href="/products/waterproof-flooring" class=" dropdown-item hover:text-blue-900 hover:bg-slate-200 rounded-md text-slate-900 ">Waterproof flooring</a>
										</li>
                    <li>
											<a href="/products/area-rugs" class=" dropdown-item hover:text-blue-900 hover:bg-slate-200 rounded-md text-slate-900 ">Area rugs</a>
										</li>
									</ul>
								</div>
							</li>
							<li className="nav-item hover:bg-slate-200 rounded-lg">
								<div className="btn-group dropend">
									<button
										type="button"
										className="btn  dropdown-toggle font-semibold text-slate-900 hover:bg-slate-200"
										data-bs-toggle="dropdown" 
										aria-expanded="false"
									>
										Flooring-101
									</button>
									<ul className="dropdown-menu">
										<li>
											<a href="/flooring-101" class=" dropdown-item hover:text-blue-900 hover:bg-slate-200 rounded-md text-slate-900  ">Flooring-101</a>
										</li>
										<li>
											<a href="/carpet-101" class=" dropdown-item hover:text-blue-900 hover:bg-slate-200 rounded-md text-slate-900 ">Carpet-101</a>
										</li>
										<li>
											<a href="/hardwood-101" class=" dropdown-item hover:text-blue-900 hover:bg-slate-200 rounded-md text-slate-900 ">Hardwood-101</a>
										</li>
										<li>
											<a href="/laminate-101" class=" dropdown-item hover:text-blue-900 hover:bg-slate-200 rounded-md text-slate-900 ">Laminate-101</a>
										</li>
										<li>
											<a href="/luxury-vinyl-101" class=" dropdown-item hover:text-blue-900 hover:bg-slate-200 rounded-md text-slate-900 ">luxury Vinyl-101</a>
										</li>
									</ul>
								</div>
							</li>
							<li className="hover:bg-slate-200 rounded-lg">

								<a href="/services" class="text-slate-900  btn px-3">Services</a>
							</li>
							<li className="hover:bg-slate-200 rounded-lg">
								<a href="/reviews" class="text-slate-900 btn px-3">Reviews</a>
							</li>
							<li className="hover:bg-slate-200 rounded-lg">
								<a href="/financing" class="text-slate-900  btn px-3">Financing</a>
							</li>
							<li className="hover:bg-slate-200 rounded-lg">
								<a href="/commercial" class="text-slate-900  btn px-3">Commercial Flooring</a>
							</li>
							<li className="hover:bg-slate-200 rounded-lg">
								<a href="/about" class="text-slate-900  btn px-3">About</a>
							</li>
							<li className="hover:bg-slate-200 rounded-lg">
								<a href="/contact" class="text-slate-900  btn px-3">Contact Us</a>
							</li>
							<li className={`md:hidden hover:bg-slate-200 rounded-lg ${menuOpen ? 'md:hidden' : 'md:hidden'}`}>
										<a href="/getintouch" class=" text-slate-900  btn px-3">Request an Estimate</a>

							</li>


						</ul>
					</div>
				</div>




			</div>


		</div>
	)
}

export default Header
