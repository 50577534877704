import React from "react";
import gallery from "../../assets/homeimg/gallery.png";

function Inspire() {
  return (
    <div className="bg-custom-blue text-white p-10  mb-10">
      <div className="container mx-auto">
        <div className="flex flex-col md:flex-row">
          <div className="md:w-1/2 md:pl-10 mt-20">
            <h2 className="md:text-3xl text-xl font-excellence mb-4">
              Get Inspired
            </h2>
            <p className="mb-2 mr-5 md:text-2xl text-[16px]">
              Let us help choose the right flooring style for your home! Browse
              our beautiful flooring galleries to get started.
            </p>
            <button
              onClick={(e) => (window.location.href = "/products")}
              className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 "
            >
              View our galleries
            </button>
          </div>
          <div className="md:w-1/2">
            <img
              src={gallery}
              alt="Living Room"
              className="rounded-lg shadow-md w-fit mt-2"
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Inspire;
