import React from "react";

function GoogleMaps() {
  return (
    <div className="w-[100vw] p-3 ">
      <iframe
        src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d6156.69181686764!2d-121.570396!3d39.506682!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x809cb5b26c5a9365%3A0x4ac1cd61caf2f10d!2sCWI%20Floors!5e0!3m2!1sen!2sus!4v1724771819140!5m2!1sen!2sus"
        width="100%"
        height="450"
        style={{ border: 0 }}
        allowFullScreen=""
        loading="lazy"
        referrerPolicy="no-referrer-when-downgrade"
      ></iframe>
    </div>
  );
}

export default GoogleMaps;
