import React from 'react'
import Header from '../Components/Header/Header'
import Established from '../Components/AboutUs/Established'
import ContactForm from "../Components/ContactUs";
import Touch from '../Components/HomeComponents/touch'
import Choose from '../Components/AboutUs/ChooseUs'
import Showroom from '../Components/AboutUs/Showroom'
import BrandLogos from '../Components/Brands/BrandLogos'
import Award from '../Components/AboutUs/award.png'
import Footer from '../Components/Footer'

function AboutUs() {
  return (
      <div>
          <div>
              <Header />
          </div>
        <h1 className="md:text-3xl text-xl font-bold mb-2 text-center mt-4">About Carpet Warehouse & Interiors</h1>
        <p className="md:text-xl text-[15px] mb-6 text-center">Get to know your Oroville, CA area flooring experts</p>
          <div>
              <Established />
          </div>
          <div>
              <Touch />
          </div>
          <p className="md:text-2xl text-[16px] mb-6 text-center mt-5">Awards & associations</p>
          <div className="flex item-center justify-center self-center">
          <img src={Award} alt='Award' className="flex item-center justify-center self-center w-[300px] mb-5 h-fit" />

          </div>
          <div>
              <Choose />
          </div>
          <div>
            
        
          </div>
          <div className="pt-10"> 
              <Showroom />
          </div>
          <div className="pb-10">
              <ContactForm />
          </div>
          <div className="border-t-2 border-gray-500">
              <BrandLogos />
          </div>
          <div>
              <Footer />
          </div>
      </div>

  )
}

export default AboutUs