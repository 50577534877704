import React from 'react'
import Header from '../Components/Header/Header'
import FlooringOptions from '../Components/Products/FlooringOptions'
import Footer from '../Components/Footer'

function Products() {
  return (
      <div>
          <div>
              <Header />
          </div>
          <div className="pt-10 pb-10">
              <FlooringOptions />
          </div>
          <div>
              <Footer />
          </div>
      </div>
  )
}

export default Products