import React from 'react';
import com from "../../assets/com.png";

function Head() {
  return (
    <div 
      className="relative h-[200px] md:h-[300px] lg:h-[200px] flex items-center justify-center w-full" 
      style={{
        backgroundImage: `url(${com})`,
     // Default value
        backgroundRepeat: 'no-repeat'
      }}
    >
      {/* Dark overlay */}
      <div className="absolute inset-0 bg-black opacity-50 z-10"></div>

      {/* Content */}
      <div className="relative z-20 text-center">
        <h2 className="text-white text-3xl font-semibold mb-4">Commercial flooring</h2>
        <button className="bg-blue-500 text-white py-2 px-6 rounded hover:bg-blue-600">
          Get in touch
        </button>
      </div>
      
      {/* Responsive Background Size */}
      <style jsx>{`
        .relative {
          background-size: 100% 100%;
        }

        @media (min-width: 1024px) {
          .relative {
            background-size: cover;
            background-position: bottom center; /* Show lower part of image on larger screens */
          }
        }
      `}</style>
    </div>
  );
}

export default Head;