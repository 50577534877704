import React from "react";
import reviews from "../../assets/homeimg/reviews.png";
import getin from "../../assets/homeimg/getin.png";
import serv from "../../assets/homeimg/serv.png";

function Touch() {
  return (
    <div className="container mx-auto px-4 py-8">
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
        <div className="bg-white rounded-md shadow-md p-4">
          <img
            src={reviews}
            alt="review"
            className="w-full h-auto rounded-lg"
          />
          <h2 className="text-xl font-bold mb-2">Reviews</h2>
          <p className="text-gray-700">
            The Oroville, CA area trusts Carpet Warehouse & Interiors for all of
            their flooring needs.
          </p>
          <a href="/reviews" className="text-blue-500 hover:underline">
            Read our reviews
          </a>
        </div>
        <div className="bg-white rounded-md shadow-md p-4">
          <img src={getin} alt="review" className="w-full h-auto rounded-lg" />
          <h2 className="text-xl font-bold mb-2">Get in touch</h2>
          <p className="text-gray-700">
            Request your accurate and professional in-home flooring estimate.
          </p>
          <a href="/contact" className="text-blue-500 hover:underline">
            Schedule now
          </a>
        </div>
        <div className="bg-white rounded-md shadow-md p-4">
          <img src={serv} alt="review" className="w-full h-auto rounded-lg" />
          <h2 className="text-xl font-bold mb-2">Services</h2>
          <p className="text-gray-700">
            Our professional flooring installers are ready to help you with your
            next project.
          </p>
          <a href="/services" className="text-blue-500 hover:underline">
            See what we offer
          </a>
        </div>
      </div>
    </div>
  );
}

export default Touch;
