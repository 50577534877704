import React from 'react'
import ContactUs from "../Components/ContactUs";
import Header from '../Components/Header/Header'
import Footer from '../Components/Footer'

function GetinTouch() {
    return (
        <div>
            <div>
                < Header />
            </div>
            <div>
                <ContactUs />
            </div>
            <div>
                <Footer />
            </div>
        </div>
        
  )
}

export default GetinTouch