import React from 'react';
import revwood from "./revwood.png";
import phenix from "./phenix.png";
import armstrong from "./armstrong.png";
import mohawk from "./mohawk.png";
import aladdin from "./aladdin.png";
import kahrs from "./kahrs.png";
import solidtech from "./solidtech.png";
import mannington from "./mannington.png";

const BrandLogos = () => {
  return (
    <div className="container mx-auto px-4 py-16">
      <h2 className="text-2xl font-bold text-center mb-10">Featured brands & technologies</h2>
      <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-4 gap-6 sm:gap-8 md:gap-10 lg:gap-12">
        <div className="flex flex-col items-center">
          <img src={revwood} alt="RevWood" className="w-32 sm:w-36 md:w-40 lg:w-48 h-auto" />
          <p className="text-sm text-gray-500 mt-2">RevWood plus</p>
        </div>
        <div className="flex flex-col items-center">
          <img src={phenix} alt="Phenix" className="w-32 sm:w-36 md:w-40 lg:w-48 h-auto" />
        </div>
        <div className="flex flex-col items-center">
          <img src={armstrong} alt="Armstrong" className="w-32 sm:w-36 md:w-40 lg:w-48 h-auto" />
        </div>
        <div className="flex flex-col items-center">
          <img src={mohawk} alt="Mohawk" className="w-32 sm:w-36 md:w-40 lg:w-48 h-auto" />
        </div>
        <div className="flex flex-col items-center">
          <img src={mannington} alt="Mannington" className="w-32 sm:w-36 md:w-40 lg:w-48 h-auto" />
        </div>
        <div className="flex flex-col items-center">
          <img src={aladdin} alt="Aladdin" className="w-32 sm:w-36 md:w-40 lg:w-48 h-auto" />
        </div>
        <div className="flex flex-col items-center">
          <img src={kahrs} alt="Kährs" className="w-32 sm:w-36 md:w-40 lg:w-48 h-auto" />
        </div>
        <div className="flex flex-col items-center">
          <img src={solidtech} alt="SolidTech" className="w-32 sm:w-36 md:w-40 lg:w-48 h-auto" />
        </div>
      </div>
    </div>
  );
};

export default BrandLogos;
