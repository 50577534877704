import React, { useRef, useEffect } from "react";
import Header from '../Components/Header/Header';
import FlooringPage from '../Components/HomeComponents/FlooringPage';
import Excellence from '../Components/HomeComponents/Excellence';
import Touch from '../Components/HomeComponents/touch';
import Inspire from '../Components/HomeComponents/Inspire';
import ContactForm from '../Components/HomeComponents/getinTouch';
import Footer from '../Components/Footer';
import BrandLogos from '../Components/Brands/BrandLogos';
import RequestEstimate from '../Components/GetInTouch';
const Home = () => {
  return (
    <div>

      <div className>
        <Header />
      </div>

      <section>
        <div id="carouselExampleInterval" class="carousel slide" data-bs-ride="carousel">
          <div class="carousel-inner">

            <div class="carousel-item active" data-bs-interval="3000">
              <div
                className="bg-cover bg-center w-full max-w-full"
                style={{
                  backgroundImage:
                    `url(${require("../images/child.jpg")})`,
                }}
              >
                <div className="w-full py-10 md:py-0 ">
                  <div className="bg-gray-900 bg-opacity-50 p-16 md:max-w-md md:p-10">
                    <img
                      src= {require("../images/pergo.png")}  //"https://res.cloudinary.com/force/image/upload/w_600/omnify/2024-campaigns/Pergo-Extreme/Pergo_DISCOVER_logo.png"
                      alt="Go Crazy - Pergo Flooring"
                      className="w-fit"
                    />
                    <h2 className="text-white font-light text-left text-2xl py-4 max-w-xs leading-tight">
                      Create memories on dentproof and waterproof floors.
                    </h2>
                    <img
                      src= "https://res.cloudinary.com/force/image/upload/w_400/omnify/2024-campaigns/Pergo-Extreme/pergo_extreme_stacked_white_logo.svg"
                      alt="Pergo Extreme"
                      className="py-6"
                      style={{ maxWidth: "150px" }}
                    />
                    {/* <a
                      href="/products"
                      className="block bg-black hover:bg-gray-800 text-white text-center font-semibold py-2 px-4 rounded-lg w-36 no-underline"
                    >
                      Shop Now
                    </a> */}
                  </div>
                </div>
              </div>
            </div>
            <div class="carousel-item flex" data-bs-interval="3000">
              <div className="bg-cover bg-top w-full max-w-full"
                style={{
                  backgroundImage: "url('https://res.cloudinary.com/force/image/upload/w_2000,h_800,c_fill,f_auto,q_auto/omnify/2024-campaigns/Karastan-Soft/11444_90116_karastan_campaign_image_21-2048x2048.jpg')"
                }}>
                <div className="w-full py-0">
                  <div className="bg-gray-900 bg-opacity-50 p-24 md:max-w-2xl md:p-16 flex flex-col justify-center">
                    <img
                      src= {require("../images/Karastan.png")}  //"https://res.cloudinary.com/force/image/upload/w_500/omnify/2024-campaigns/Karastan-Soft/Karastan-SmartStrand-White-SVG.png"
                      alt="Karastan SmartStrand"
                      className="w-fit mx-auto mb-10"
                    />
                    <h3 className="font-light text-center text-white text-lg my-10 leading-tight">
                      Made for good company — and for easy cleanup.
                    </h3>
                    <a
                      href="/products/carpet"
                      className="block bg-black hover:bg-gray-800 text-white text-center font-light py-2 px-4 rounded-lg w-36 mx-auto no-underline"
                    >
                      Explore
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleInterval" data-bs-slide="prev">
            <span class="carousel-control-prev-icon" aria-hidden="true"></span>
            <span class="visually-hidden">Previous</span>
          </button>
          <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleInterval" data-bs-slide="next">
            <span class="carousel-control-next-icon" aria-hidden="true"></span>
            <span class="visually-hidden">Next</span>
          </button>
        </div>

      </section>


      <div className="pt-1 pb-12">
        <FlooringPage />
      </div>
      <div>
        <Excellence />
      </div>
      <div>
        <Touch />
      </div>
      <div >
        <Inspire />
      </div>
      <div>
        <RequestEstimate />
      </div>
      <div>
        <BrandLogos />
      </div>
      <div>
        <Footer />
      </div>
    </div>
  );
};

export default Home;
