import React from 'react';
import est from '../../assets/est.png';

const Established = () => {
  return (
    <div className="bg-custom-blue text-white p-10">
      <div className="container mx-auto">
        <div className="flex flex-col md:flex-row">
          <div className="md:w-1/2">
            <img 
              src={est} 
              alt="Living Room" 
              className="rounded-lg shadow-md w-full h-auto md:w-[900px] md:h-[660px] lg:w-full lg:h-auto" 
            />
          </div>
          <div className="md:w-1/2 md:pl-10 mt-20">
            <h2 className=" md:text-3xl text-xl font-excellence mb-4">Established in 1972 in Oroville, California.</h2>
            <p className="mb-2 mr-5 md:text-2xl text-[16px]">
              We have been serving the flooring needs in Butte County and surrounding areas, both residential and commercial customers, for over 45 years.
            </p>
            <br />
            <br />
            <p className="mb-4 mr-5 md:text-2xl text-[16px]">
              Today along with carpet, we carry a complete line of hardwood, laminate, tile, vinyl, luxury vinyl, and area rugs. We have long-standing relationships with flooring manufacturers and suppliers.
            </p>
            <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4">Learn more about us</button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Established;
