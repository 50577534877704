import React from 'react';
import Facebook from '../assets/homeimg/Facebook.svg';
import Twitter from '../assets/homeimg/Twitter.svg';
import Pinterest from '../assets/homeimg/Pinterest.svg';
import Instagram from '../assets/homeimg/Instagram.svg';
import Yelp from '../assets/homeimg/Yelp.svg';
import Google from '../assets/homeimg/Google.svg';
import Synchrony from '../assets/homeimg/Synchrony.svg';
import Mastercard from '../assets/homeimg/Mastercard.svg';
import Visa from '../assets/homeimg/Visa.svg';
import AmericanExpress from '../assets/homeimg/AmericanExpress.svg';
import Discover from '../assets/homeimg/Discover.svg';
import Omnify from '../assets/homeimg/Omnify.svg';
import Broadlume from '../assets/homeimg/Broadlume.png';
import ArrowUp from '../assets/homeimg/ArrowUp.png';

function Footer() {
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };

  return (
    <footer className="bg-gray-100 pt-10 pb-6 px-4 sm:px-6 lg:px-8 border-t-8 border-yellow-700 w-full">
      <div className="container mx-auto">
        <div className="flex flex-col md:flex-row justify-between items-center md:items-start mb-8">
          <div className="flex space-x-4 mb-4 md:mb-0">
            <a href='https://www.facebook.com/CWIfloors'>
              <img src={Facebook} alt="Facebook" className="w-8 h-8" />
            </a>
            <a href='https://x.com/cwi_floors'>
              <img src={Twitter} alt="Twitter" className="w-8 h-8" />

            </a>
            <a href='https://www.pinterest.com/cwifloors/'>
              <img src={Pinterest} alt="Pinterest" className="w-8 h-8" />

            </a>
            <a href='https://www.instagram.com/cwifloors/'>
              <img src={Instagram} alt="Instagram" className="w-8 h-8" />

            </a>
            <a href='https://www.yelp.com/biz/cwi-floors-oroville'>
              <img src={Yelp} alt="Yelp" className="w-8 h-8" />

            </a>
          </div>
          <div className="flex flex-col items-center md:items-start w-full md:w-auto">
            <div className="flex flex-wrap justify-center md:justify-start space-x-4 md:space-x-10 mb-4">
              <a href="/" className="text-gray-700 hover:text-gray-900 font-semibold mb-2 md:mb-0">HOME</a>
              <a href="/about" className="text-gray-700 hover:text-gray-900 font-semibold mb-2 md:mb-0">COMPANY</a>
              <a href="/products" className="text-gray-700 hover:text-gray-900 font-semibold mb-2 md:mb-0">PRODUCTS</a>
              <a href="/contact" className="text-gray-700 hover:text-gray-900 font-semibold mb-2 md:mb-0">CONTACT</a>
            </div>
          </div>
          <div className="mt-4 md:mt-0">
            <img src={ArrowUp} alt="Scroll to top" className="w-8 h-8 cursor-pointer" onClick={scrollToTop} />
          </div>
        </div>

        <div className="flex flex-col md:flex-row justify-between space-y-8 md:space-y-0 md:space-x-8">
          <div className="w-full md:w-1/2 lg:w-1/3">
            {/* <div className="bg-blue-100 rounded-md p-6 shadow-md">
              <div className="flex items-center mb-2">
                <div className="bg-orange-500 text-white rounded-full w-12 h-12 flex items-center justify-center text-xl font-bold mr-4">
                  ZU
                </div>
                <div>
                  <h3 className="text-lg font-bold">Zu McDonald</h3>
                  <p className="text-gray-600 text-sm">Mar 29, 2024</p>
                </div>
              </div>
              <div className="flex items-center mb-2">
                <span className="text-xl font-bold mr-2">5.0</span>
                <div className="flex">
                  {[...Array(5)].map((_, i) => (
                    <svg key={i} className="w-5 h-5 text-yellow-400" fill="currentColor" viewBox="0 0 20 20">
                      <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
                    </svg>
                  ))}
                </div>
                <img src={Google} alt="Google" className="w-5 h-5 ml-2" />
              </div>
              <p className="text-sm">Truly excellent customer service!</p>
            </div> */}
          </div>
          <div className="w-full md:w-1/2 lg:w-2/3 flex flex-col items-center md:items-start text-left">
            <h2 className="text-2xl font-bold mb-4 text-center md:text-left">Carpet Warehouse & Interiors</h2>
            <p className="text-gray-600 mb-2 text-center md:text-left">1560 Feather River Blvd Oroville, CA 95965</p>
            <a href="tel:+15305335970" className="text-blue-600 hover:text-blue-800 font-semibold mb-4 text-center md:text-left">(530) 533-5970</a>
            <div className="flex flex-wrap gap-4 justify-center md:justify-start">
              <img src={Synchrony} alt="Synchrony" className="h-8" />
              <img src={Mastercard} alt="Mastercard" className="h-8" />
              <img src={Visa} alt="Visa" className="h-8" />
              <img src={AmericanExpress} alt="American Express" className="h-8" />
              <img src={Discover} alt="Discover" className="h-8" />
            </div>
          </div>
        </div>
        
        <div className="mt-8 text-center text-gray-500 text-sm">
          <div className="mt-12 flex justify-center space-x-8">
            <img src={Omnify} alt="Omnify" className="h-8" />
    
          </div>
          <p className="flex flex-wrap justify-center">
            <span className="mr-1">Copyright © 2024 Carpet Warehouse & Interiors</span>
            <span className="mx-1">|</span>
            <a href="" className="text-gray-700 hover:text-gray-900 mx-1">Privacy</a>
            <span className="mx-1">|</span>
            <a href="" className="text-gray-700 hover:text-gray-900 mx-1">Accessibility</a>
          </p>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
