import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './App.css';
import Home from './Screens/Home';
import Products from './Screens/Products';
import Services from './Screens/Services';
import AboutUs from './Screens/AboutUs';
import CommercialFlooring from './Screens/CommercialFlooring';
import GetinTouch from './Screens/GetinTouch';
import Glasstile from './Screens/GlassTile.jsx';
import ProductDetail from './Screens/ProductDetails';
import Contact from './Screens/Contact.jsx';
import Reviews from './Screens/Reviews.jsx'
import Financing from './Screens/Financing.jsx'
import Flooring101Article from './Screens/Flooring101Article.jsx'

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/products" element={<Products />} />
        <Route path="/services" element={<Services />} />
        <Route path="/about" element={<AboutUs />} />
        <Route path="/commercial" element={<CommercialFlooring />} />
        <Route path="/getintouch" element={<GetinTouch />} />
        <Route path='/products/:id' element={<Glasstile />}/>
        <Route path="/products/:category/:id" element={<ProductDetail />} /> 
        <Route path='/contact' element={<Contact />} />
      <Route path='/reviews' element={<Reviews/>}></Route>
      <Route path='/financing' element={<Financing/>}></Route>
      
      <Route path='/:cid' element={<Flooring101Article/>}></Route>
      <Route path='/:cid/articles/:id' element={<Flooring101Article />}></Route>
      
      </Routes>
    </Router>
    
  );
}

export default App;
