import React, { useState, useEffect } from "react";
import Header from "../Components/Header/Header";
import 'bootstrap/dist/css/bootstrap.min.css';
import Footer from "../Components/Footer";
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { useParams } from "react-router-dom";
import home5 from '../assets/laminate.png';

function Glasstile() {
    const { id } = useParams();
    const [imageData, setImageData] = useState([]);
    const [uniquebrand, setuniquebrand] = useState([]);
    const [uniquewidth, setuniquewidth] = useState([]);
    const [uniqueColor, setUniqueColor] = useState([]);
    const [currentPage, setCurrentPage] = useState(0);
    const itemsPerPage = 12;
    const startIndex = currentPage * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    const pageData = imageData && imageData.slice(startIndex, endIndex);
    const navigate = useNavigate();
   

    useEffect(() => {
        const fetchData = async () => {
            try {
                const formdata = { idd: id };
                const imageDataResponse = await axios.post(`${process.env.REACT_APP_API_URL}/api/fetchimage`, formdata);
                 const { data1, data2, data3, data4 } = imageDataResponse.data;

            setImageData(data1);
            setuniquebrand(data2);
            setuniquewidth(data3);
            setUniqueColor(data4);

            setFormData((prevData) => ({
                ...prevData,
                brand: null,
                Width: null,
                color: null,
            }));

                setWidthLabel(getWidthLabel());
            

               
            } catch (error) {
                console.error(error);
            }
        };

        fetchData();
    }, [id]);

    const handlepropertyselect = (id, category) => {
        navigate(`/products/${category}/${id}`);
        window.scrollTo(0, 0);
    }

    const totalPages = imageData ? Math.ceil(imageData.length / itemsPerPage) : 0;

    const handleNextPage = () => {
        setCurrentPage(currentPage + 1 < totalPages ? currentPage + 1 : totalPages - 1);
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    const handlePrevPage = () => {
        setCurrentPage(currentPage > 0 ? currentPage - 1 : 0);
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    const handlePageClick = (page) => {
        setCurrentPage(page);
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    const renderPaginationButtons = () => {
        const buttons = [];
        for (let i = currentPage; i < totalPages && i < currentPage + 4; i++) {
            buttons.push(
                <li key={i} className={`page-item ${currentPage === i ? 'active' : ''} p-2`}>
                    <button
                        type="button"
                        className={`btn ${currentPage === i ? 'btn-primary' : 'btn-secondary'}`}
                        onClick={() => handlePageClick(i)}
                    >
                        {i + 1}
                    </button>
                </li>
            );
        }
        return buttons;
    };

    const [formData, setFormData] = useState({
        Types: id,
        brand: null,
        Width: null,
        color: null,
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const [widthLabel, setWidthLabel] = useState('');

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const imageDataResponse = await axios.post(`${process.env.REACT_APP_API_URL}/api/fetchimage/similarimage`, formData);
            setImageData(imageDataResponse.data.data1);
            setuniquebrand(imageDataResponse.data.data2);
            setuniquewidth(imageDataResponse.data.data3);
            setUniqueColor(imageDataResponse.data.data4);
            onCloseMenu();
            setWidthLabel(getWidthLabel());
        } catch (error) {
            console.error(error);
        }

       
    };

    


   


    


    

    const handleReset = () => {
        setFormData({
            Types: id,
            brand: null,
            Width: null,
        });
    };

    const [menuOpen, setMenuOpen] = useState(false);

    const onToggleMenu = () => {
        setMenuOpen(true);
    };

    const onCloseMenu = () => {
        setMenuOpen(false);
    };

    const [visibleItems, setVisibleItems] = useState(4);
    const showMoreItems = () => {
        setVisibleItems(visibleItems + 4);
    };


    const getWidthLabel = () => {
        switch (formData.Types) {
            case 'carpet':
                return 'Fiber Type';
            case 'hardwood':
                return 'Installation Method';
            case 'vinyl':
                return '';
            case 'area-rugs':
                return '';
            case 'waterproof-flooring':
                return 'Installation Method';
            case 'luxury-vinyl':
                return 'Installation Method';
            default:
                return 'Width';
        }
    }

     

    return (
        <div>
            <Header />
            <section className="hero-wrap hero-wrap-2" style={{ backgroundImage: `url(${home5})` }} data-stellar-background-ratio="0.5">
                <div className="overlay" style={{ background: 'rgba(0, 0, 0, 0.3)' }}>
                    <div className="container">
                        <div className="row no-gutters slider-text align-items-end justify-content-center">
                            <div className="col-md-9 ftco-animate mb-2 text-center p-5 text-white">
                                <h2 className=""><div className='text-white capitalize'>{id}</div></h2>
                                <p className="mb-2 text-lg font-normal text-white lg:text-xl sm:px-16 xl:px-48">Wide variety of styles</p>
                                <a href='# '><div className='btn btn-primary'>View Our Section</div></a>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <div className="container p-5">
                <div className="row">
                    <div className="col-12 col-md-4 col-lg-3">
                        <div className="flex items-center lg:order-2">
                            <button onClick={onToggleMenu}
                                name={menuOpen ? 'close' : 'menu'}
                                className="text-3xl cursor-pointer md:hidden">
                                <span className="sr-only">Open main menu</span>
                                <svg className="w-6 h-6" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z" clipRule="evenodd"></path></svg>
                                <svg className="hidden w-6 h-6" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                            </button>
                        </div>
                        <div
                            className={`nav-links duration-500 md:static absolute rounded w-full left-0 ${menuOpen ? 'top-[20%]' : 'top-[-500%]'
                                } md:w-auto flex items-center`} style={{ backgroundColor: '#F6F5F5' }}
                        >
                            <div className="flex md:flex-row flex-col md:items-left rounded md:justify-start p-2 border-y-2  border-white" style={{ backgroundColor: '#F6F5F5' }}>
                                <form className="max-w-sm mx-auto px-3" onSubmit={handleSubmit}>
                                    <fieldset>
                                        <legend>Types</legend>
                                        <div className="flex items-center mb-2 px-3">
                                            <input
                                                type="radio"
                                                id="carpet"
                                                name="Types"
                                                value="carpet"
                                                onChange={handleChange}
                                                checked={formData.Types === 'carpet'}
                                                required
                                            />
                                            <label htmlFor="carpet" className="block ms-2 text-sm font-medium text-gray-900 primary:text-gray-900">
                                                Carpet
                                            </label>
                                        </div>
                                        <div className="flex items-center mb-2 px-3">
                                            <input
                                                type="radio"
                                                id="laminate"
                                                name="Types"
                                                value="laminate"
                                                onChange={handleChange}
                                                checked={formData.Types === 'laminate'}
                                                required
                                            />
                                            <label htmlFor="luxuryviyl" className="block ms-2 text-sm font-medium text-gray-900 primary:text-gray-900">
                                                Laminate
                                            </label>
                                        </div>
                                        <div className="flex items-center mb-2 px-3">
                                            <input
                                                type="radio"
                                                id="hardwood"
                                                name="Types"
                                                value="hardwood"
                                                onChange={handleChange}
                                                checked={formData.Types === 'hardwood'}
                                                required
                                            />
                                            <label htmlFor="hardwood" className="block ms-2 text-sm font-medium text-gray-900 primary:text-gray-900">
                                                Hardwood
                                            </label>
                                        </div>
                                        <div className="flex items-center mb-2 px-3">
                                            <input
                                                type="radio"
                                                id="vinyl"
                                                name="Types"
                                                value="vinyl"
                                                onChange={handleChange}
                                                checked={formData.Types === 'vinyl'}
                                                required
                                            />
                                            <label htmlFor="vinyl" className="block ms-2 text-sm font-medium text-gray-900 primary:text-gray-900">
                                                Vinyl
                                            </label>
                                        </div>
                                        <div className="flex items-center mb-2 px-3">
                                            <input
                                                type="radio"
                                                id="area-rugs"
                                                name="Types"
                                                value="area-rugs"
                                                onChange={handleChange}
                                                checked={formData.Types === 'area-rugs'}
                                                required
                                            />
                                            <label htmlFor="area-rugs" className="block ms-2 text-sm font-medium text-gray-900 primary:text-gray-900">
                                                Area Rugs
                                            </label>
                                        </div>
                                        <div className="flex items-center mb-2 px-3">
                                            <input
                                                type="radio"
                                                id="waterproof-flooring"
                                                name="Types"
                                                value="waterproof-flooring"
                                                onChange={handleChange}
                                                checked={formData.Types === 'waterproof-flooring'}
                                                required
                                            />
                                            <label htmlFor="waterproof-flooring" className="block ms-2 text-sm font-medium text-gray-900 primary:text-gray-900">
                                                Waterproof Flooring
                                            </label>
                                        </div>
                                        <div className="flex items-center mb-4 px-3">
                                            <input
                                                type="radio"
                                                id="luxury-vinyl"
                                                name="Types"
                                                value="luxury-vinyl"
                                                onChange={handleChange}
                                                checked={formData.Types === 'luxury-vinyl'}
                                                required
                                            />
                                            <label htmlFor="luxury-vinyl" className="block ms-2 text-sm font-medium text-gray-900 primary:text-gray-900">
                                                Luxury Vinyl
                                            </label>
                                        </div>
                                    </fieldset>

                                    <fieldset>
                                        <legend>Brands</legend>
                                        {Array.isArray(uniquebrand) && uniquebrand.map((item) => (
                                            <div className="flex items-center mb-2 px-3" key={item.Brand}>
                                                <input
                                                    type="radio"
                                                    id={item.Brand}
                                                    name="brand"
                                                    value={item.Brand}
                                                    onChange={handleChange}
                                                    checked={formData.brand === item.Brand}
                                                />
                                                <label htmlFor={item.Brand} className="block ms-2 text-sm font-medium text-gray-900 primary:text-gray-900">
                                                    {item.Brand}
                                                </label>
                                            </div>
                                        ))}
                                    </fieldset>
                                    <fieldset>
                                        <legend>{widthLabel}</legend>
                                        {uniquewidth.slice(0, visibleItems).map((item) => (
                                            (item.Installation_Method || item.FiberType || item.color) && (
                                                <div className="flex items-center mb-2 px-3" key={item.Width}>
                                                    <input
                                                        type="radio"
                                                        id={item.Installation_Method || item.FiberType || item.color}
                                                        name="Width"
                                                        value={item.Installation_Method || item.FiberType || item.color}
                                                        onChange={handleChange}
                                                        checked={formData.Width === (item.Installation_Method || item.FiberType || item.color)}
                                        
                                                    />
                                                    <label htmlFor={item.Installation_Method || item.FiberType || item.color} className="block ms-2 text-sm font-medium text-gray-900 primary:text-gray-900">
                                                        {item.Installation_Method || item.FiberType || item.color}
                                                    </label>
                                                </div>
                                            )
                                        ))}
                                        {visibleItems < uniquewidth.length ? (
                                            <button onClick={showMoreItems} className="text-blue-500 hover:underline cursor-pointer">
                                                More Items
                                            </button>
                                        ) : null}
                                    </fieldset>

                                    <div className="flex justify-between">
                                        <button type="button" className="btn btn-secondary" onClick={handleReset}>
                                            Reset
                                        </button>
                                        <button type="submit" className="btn btn-primary">Submit</button>
                                    </div>
                                </form>
                                <br />
                                <div className={`md:hidden ${menuOpen ? 'md:hidden' : 'md:hidden'}`}>
                                    <button className="font-semibold hover:text-purple-600" onClick={onCloseMenu}>
                                        Close
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-md-8 col-lg-9">
                        <p className="mb-2 text-lg font-normal text-gray-500 lg:text-xl sm:px-16 xl:px-48">Products from CWI Floors in Oroville, CA</p>
                        <div className="row mb-2">
                            {pageData.map((item) => (
                                <div key={item.SKU} className="col-12 col-md-6 col-lg-4 p-3" id={item.Brand}>
                                    <button onClick={() => handlepropertyselect(item.SKU, item.Type)}>
                                        <div className="card-box-c p-3" style={{ border: '2px solid #A9B6D3', borderRadius: '15px' }}>
                                            <div className="img-box-a">
                                                <img src={require('../Components/photo/' + item.SKU + '.png')} alt="Los Angeles" className="d-block img-fluid" title={item.Title} onError={(e) => {
                                                    e.target.src = '../Components/photo/3A46-989.png'; // Set the path to your default image
                                                }} />
                                            </div>
                                            <div className="row">
                                                <strong className="flex items-center text-sm font-semibold text-gray-700">{item.Brand}</strong><br />
                                                <strong className="flex items-center text-sm font-semibold text-gray-700">{item.Product_Line}</strong><br />
                                                <strong className="flex items-center text-sm font-semibold text-gray-700">{item.color}</strong>
                                            </div>
                                            <br />
                                            <div className='btn btn-primary w-75'>See More colors</div>
                                        </div>
                                    </button>
                                </div>
                            ))}
                        </div>
                        <div className="row">
                            <div className="col-12">
                                <nav className="pagination-a">
                                    <ul className="pagination justify-content-center flex">
                                        <li className="page-item p-2">
                                            <button
                                                type="button"
                                                className="btn btn-primary"
                                                onClick={handlePrevPage}
                                            >
                                                &laquo; Previous
                                            </button>
                                        </li>
                                        {renderPaginationButtons()}
                                        <li className="page-item p-2">
                                            <button
                                                type="button"
                                                className="btn btn-primary"
                                                onClick={handleNextPage}
                                            >
                                                Next &raquo;
                                            </button>
                                        </li>
                                    </ul>
                                </nav>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
}

export default Glasstile;