import React, { useRef, useEffect } from "react";
import 'bootstrap-icons/font/bootstrap-icons.css';
import Headers from '../Components/Header/Header'
import Footer from '../Components/Footer'
import myImage from '../Components/google-review.png';
function Reviews() {
  const widgetInitialized = useRef(false);
  useEffect(() => {
    const scriptId = "salescaptain-review-widget-script";

    // Ensure widget is not initialized twice
    if (!widgetInitialized.current) {
      widgetInitialized.current = true;
      if (!document.getElementById(scriptId)) {
        const script = document.createElement("script");
        script.id = scriptId;
        script.src =
          "https://app.salescaptain.com/api/v1/user/review-widget/b32ae67f-ad57-47e2-b391-934dc01b60cd";
        script.defer = true;
        document.body.appendChild(script);
      }
    }
  }, []);
  return (
    <div>
      <div><Headers /> </div>
      <div className='container'>
        <div className='text-center font-bold '>
          <span style={{ fontSize: '35px' }}>Reviews</span>
        </div>
      </div>
      <div id="salescaptain-review-widget"></div>
      <div className='sm:pt-[170px]'><Footer /> </div>
    </div>
  )
}

export default Reviews
