import React from "react";

const StoreInfo = () => {
  return (
    <div className="p-6 bg-white max-w-sm border rounded-lg shadow-md justify-start ">
      <h2 className="text-xl font-bold mb-4">Store info</h2>

      <div className="mb-4">
        <h3 className="font-semibold">Address</h3>
        <p className="font-excellence">1560 Feather River Blvd Oroville, CA 95965</p>
        <a
          href="https://www.google.com/maps/dir//CWI+Floors+1560+Feather+River+Blvd+Oroville,+CA+95965/@39.5066821,-121.5703956,15z/data=!4m5!4m4!1m0!1m2!1m1!1s0x809cb5b26c5a9365:0x4ac1cd61caf2f10d"
          className="text-blue-600 hover:underline"
        >
          Get directions
        </a>
      </div>

      <div className="mb-4">
        <h3 className="font-semibold">Phone</h3>
        <p className="font-excellence">(530) 533-5970</p>
        <a href="tel:+15305335970" className="text-blue-600 hover:underline">
          Call us
        </a>
      </div>

      <div className="mb-4">
        <h3 className="font-semibold">Hours</h3>
        <p className="font-excellence">Mon-Fri 8am - 5pm</p>
        <p className="font-excellence">And by appointment after regular hours and on the weekend.</p>
        <a
          href="/getintouch"
          className="text-blue-600 hover:underline"
        >
          Get in touch
        </a>
      </div>
    </div>
  );
};

export default StoreInfo;
