import React from 'react';
import { Link } from 'react-router-dom';
import waterproof from '../../assets/waterproof.png';
import carpet from '../../assets/carpet.png';
import laminate from '../../assets/laminate.png';
import vinyl from '../../assets/vinyl.png';
import hardwood from '../../assets/hardwood.png';

function FlooringPage() {
  return (
    <div className="flex flex-col items-center text-center bg-gradient-to-b from-gray-200 to-white">
      <div className="container w-4/5  mt-8">
        <h1 className="md:text-5xl text-xl font-bold mb-4">Looking for new floors in the Oroville, CA area?</h1>
        <p className="md:text-2xl text-[15px] mb-6">Shop our extensive flooring catalog</p>
        <div className="grid xl:grid-cols-5 md:grid-cols-3 grid-cols-2 items-center  justify-center gap-5 mt-4">
          <div className="relative  flex flex-col items-center">
            <Link to="/products/waterproof-flooring">
              <img src={waterproof} alt="Waterproof Floor" className="w-full h-auto rounded-lg"/>
              <div className="absolute bottom-2 left-0 right-0 bg-black bg-opacity-50 text-white text-lg py-1 px-2 rounded-lg">Waterproof</div>
            </Link>
          </div>
          <div className="relative  flex flex-col items-center">
            <Link to="/products/carpet">
              <img src={carpet} alt="Carpet Floor" className="w-full h-auto rounded-lg"/>
              <div className="absolute bottom-2 left-0 right-0 bg-black bg-opacity-50 text-white text-lg py-1 px-2 rounded-lg">Carpet</div>
            </Link>
          </div>
          <div className="relative  flex flex-col items-center">
            <Link to="/products/laminate">
              <img src={laminate} alt="Laminate Floor" className="w-full h-auto rounded-lg"/>
              <div className="absolute bottom-2 left-0 right-0 bg-black bg-opacity-50 text-white text-lg py-1 px-2 rounded-lg">Laminate</div>
            </Link>
          </div>
          <div className="relative  flex flex-col items-center">
            <Link to="/products/luxury-vinyl">
              <img src={vinyl} alt="Luxury Vinyl Floor" className="w-full h-auto rounded-lg"/>
              <div className="absolute bottom-2 left-0 right-0 bg-black bg-opacity-50 text-white text-lg py-1 px-2 rounded-lg">Luxury Vinyl</div>
            </Link>
          </div>
          <div className="relative hidden  md:flex flex-col items-center">
            <Link to="/products/hardwood">
              <img src={hardwood} alt="Hardwood Floor" className="w-full h-auto rounded-lg"/>
              <div className="absolute bottom-2 left-0 right-0 bg-black bg-opacity-50 text-white text-lg py-1 px-2 rounded-lg">Hardwood</div>
            </Link>
          </div>
        </div>
        <p className="mt-6">
          <a href="/products" className="text-blue-500 hover:underline">View all products</a>
        </p>
      </div>
    </div>
  );
}

export default FlooringPage;
