import React from "react";
import s1 from "../../assets/showroom/s1.png"
import s2 from "../../assets/showroom/s2.png"
import s3 from "../../assets/showroom/s3.png"
import s4 from "../../assets/showroom/s4.png"
import s5 from "../../assets/showroom/s5.png"
import s6 from "../../assets/showroom/s6.png"


const Showroom = () => {
  return (
    <div className="container mx-auto">
      <h1 className="text-4xl  text-center mb-4 font-excellence">
        Our showroom has it all
      </h1>
      <p className="text-center text-gray-600 mb-8 text-lg tracking-widest">
        YOU'LL WANT TO SEE IT FOR YOURSELF
      </p>

      <div className="grid grid-cols-2 gap-4">
        <div className="relative">
          <img
            src={s1}
            alt="Showroom 1"
            className="w-full h-auto"
          />
        </div>
        <div className="relative">
          <img
            src={s2}
            alt="Showroom 2"
            className="w-full h-auto"
          />
        </div>
        <div className="relative">
          <img
            src={s3}
            alt="Showroom 3"
            className="w-full h-auto"
          />
        </div>
        <div className="relative">
          <img
            src={s4}
            alt="Showroom 4"
            className="w-full h-auto"
          />
        </div>
        <div className="relative">
          <img
            src={s5}
            alt="Showroom 5"
            className="w-full h-auto"
          />
        </div>
        <div className="relative">
          <img
            src={s6}
            alt="Showroom 6"
            className="w-full h-auto"
          />
        </div>
      </div>
    </div>
  );
};

export default Showroom;
