import React from "react";
import Header from "../Components/Header/Header";
import Footer from "../Components/Footer";
import ContactUs from "../Components/ContactUs";
import StoreInfo from "../Components/StoreInfo";
import GoogleMaps from "../Components/GoogleMaps";

function Contact() {
  return (
    <div className="flex flex-col min-h-screen">
      <Header />

      <div className="flex flex-col lg:flex-row justify-center items-center lg:space-x-8 space-y-8 lg:space-y-0 mb-16 px-4">
        <ContactUs />
        <StoreInfo />

      </div>
      <div className="flex-grow">
        <GoogleMaps />
      </div>
      <Footer />
    </div>
  );
}

export default Contact;
