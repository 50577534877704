import React from 'react'
import Header from '../Components/Header/Header'
import Head from '../Components/Commercial/florringHead'
import CallUs from '../Components/Commercial/Callus';
import ContactForm from "../Components/ContactUs";
import Footer from '../Components/Footer';

function CommercialFlooring() {
  return (
      <div>
          <div>
              <Header />
          </div>
          <div>
            <Head />
          </div>
          <div className="grid grid-cols-1 md:grid-cols-2 md:px-[30px]  gap-4">
              <CallUs />
              <ContactForm />
          </div>
          <div>
              <Footer />
          </div>
      </div>
  )
}

export default CommercialFlooring;