import React from 'react';
import excellence from '../../assets/excellence.png';

const Excellence = () => {
  return (
    <div className="bg-custom-blue text-white p-10">
      <div className="container mx-auto">
        <div className="flex flex-col md:flex-row">
          <div className="md:w-1/2">
            <img src={excellence} alt="Living Room" className="rounded-lg shadow-md w-full" />
          </div>
          <div className="md:w-1/2 md:pl-10 mt-20">
            <h2 className="md:text-3xl text-xl font-excellence mb-4">Commitment to excellence</h2>
                      <p className="mb-2 mr-5 md:text-2xl text-[16px]">We have been serving the flooring needs in Butte County and surrounding areas, both residential and commercial customers, for over 45 years.</p>
                      <br></br>
                      <br></br>
            <p className="mb-4 mr-5 md:text-2xl text-[16px]">Today along with carpet, we carry a complete line of hardwood, laminate, tile, vinyl, luxury vinyl, and area rugs. We have long-standing relationships with flooring manufacturers and suppliers.</p>
            <button
              onClick={() => window.location.href = '/about'}
              className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 ">Learn more about us</button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Excellence;