import React from 'react';
import Call from "../../assets/Call.png"

function CallUs() {
  return (
    <div className="p-4">
      <h1 className="text-4xl font-excellence text-center mb-4">Commercial Flooring</h1>
      <p className="text-left mb-4 text-base font-serif">
        Carpet Warehouse & Interiors in Oroville, CA provides commercial flooring
        sales, service, and installation services. Builders, architects, and
        designers are welcome to use our showroom to meet with clients so they can
        see multiple product options in one convenient and comfortable location.
        Give us a call (530) 460-6420 or fill out the contact form below and find
        out how CWI Floors can help with your next project.
      </p>
      <div className="flex justify-center">
        <img
          src={Call}
          alt="Commercial Flooring Showroom"
          className="rounded-md shadow-md"
        />
      </div>
      {/* <div className="flex justify-center mt-4">
        <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
          Get in touch
        </button>
      </div> */}
    </div>
  );
}

export default CallUs;