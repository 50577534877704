import React from 'react';
import us from '../../assets/us.png';
import { Link } from 'react-router-dom';

const Choose = () => {
  return (
    <div className="bg-custom-blue text-white p-10">
      <div className="container mx-auto">
        <div className="flex flex-col md:flex-row">
          
          <div className="md:w-1/2 md:pl-10 mt-2">
            <h2 className="text-xl md:text-3xl  font-excellence mb-4">Why should you choose us?</h2>
            <p className="mb-2 mr-5 md:text-2xl text-[16px]">
              Our staff is constantly training on the newest products and technology that are available in the flooring industry. We make it a point to attend all Manufactures seminars and installation schools.
            </p>
            <br />
            <br />
            <p className="mb-4 mr-5 md:text-2xl text-[16px]">
             CEO and President of the Company, Richard Leming, has been a member of three different manufacturers advisory boards. With over 40 years in the industry, he has been with CWI Floors since its early beginnings. He has helped the industry reach a higher degree of professionalism, and is currently working hard to keep up these same values in his company today.
            </p>
            <Link to="/products">
              <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4">Browse our Catalog</button>
              </Link>
                  </div>
                  <div className="md:w-1/2">
            <img 
              src={us} 
              alt="Living Room" 
              className="rounded-lg mt-6 shadow-md w-full h-auto md:w-auto md:h-[500px] lg:w-full lg:h-auto" 
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Choose;
