import React from 'react';
import { Link } from 'react-router-dom';
import Carpet from '../../assets/Products/Carpet.png';
import Hardwood from '../../assets/Products/Hardwood.png';
import Laminate from '../../assets/Products/Laminate.png';
import Vinyl from '../../assets/Products/Vinyl.png';
import Waterproof from '../../assets/Products/Waterproof.png';
import Nvinyl from '../../assets/Products/Nvinyl.png';
import AreaRugs from '../../assets/Products/AreaRugs.png';

const FlooringOptions = () => {
  return (
    <div className="container mx-auto px-4">
      <h1 className="text-3xl font-bold text-center mb-6">
        Shopping for new floors in the Oroville, CA area?
      </h1>
      <p className="text-center text-gray-600 mb-10">
        BROWSE THE MOST EXTENSIVE LOCAL ONLINE FLOORING CATALOG
      </p>
      <div className="grid grid-cols-2 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 xl:grid-cols-3 gap-4 justify-center">
        <div className="flex flex-col items-center">
          <Link to="/products/carpet">
            <img
              src={Carpet}
              alt="Carpet"
              className="w-full h-48 md:h-64 lg:h-80 object-cover"
            />
            <p className="text-center text-blue-500 hover:text-blue-900 font-bold mt-1">Carpet</p>
          </Link>
        </div>
        <div className="flex flex-col items-center">
          <Link to="/products/hardwood">
            <img
              src={Hardwood}
              alt="Hardwood"
              className="w-full h-48 md:h-64 lg:h-80 object-cover"
            />
            <p className="text-center text-blue-500 hover:text-blue-900 font-bold mt-1">Hardwood</p>
          </Link>
        </div>
        <div className="flex flex-col items-center">
          <Link to="/products/laminate">
            <img
              src={Laminate}
              alt="Laminate"
              className="w-full h-48 md:h-64 lg:h-80 object-cover"
            />
            <p className="text-center text-blue-500 hover:text-blue-900 font-bold mt-1 mb-7">Laminate</p>
          </Link>
        </div>

        {/* Placing Area Rugs in the middle */}

        <div className="flex flex-col items-center">
          <Link to="/products/vinyl">
            <img
              src={Vinyl}
              alt="Luxury vinyl"
              className="w-full h-48 md:h-64 lg:h-80 object-cover"
            />
            <p className="text-center text-blue-500 hover:text-blue-900 font-bold mt-1 mb-7">Luxury vinyl</p>
          </Link>
        </div>
        <div className="flex flex-col items-center">
          <Link to="/products/waterproof-flooring">
            <img
              src={Waterproof}
              alt="Waterproof"
              className="w-full h-48 md:h-64 lg:h-80 object-cover"
            />
            <p className="text-center  text-blue-500 hover:text-blue-900 font-bold mt-1 mb-2">Waterproof</p>
          </Link>
        </div>
        <div className="flex flex-col items-center">
          <Link to="/products/vinyl">
            <img
              src={Nvinyl}
              alt="Vinyl"
              className="w-full h-48 md:h-64 lg:h-80 object-cover"
            />
            <p className="text-center text-blue-500 hover:text-blue-900 font-bold mt-1 mb-2">Vinyl</p>
          </Link>
        </div>
         <div className="flex flex-col items-center md:col-span-3 lg:col-span-3 order-last md:order-none">
          <Link to="/products/area-rugs">
            <img
              src={AreaRugs}
              alt="Area rugs"
              className="w-full h-48 md:h-64 lg:h-80 object-cover"
            />
            <p className="text-center text-blue-500 hover:text-blue-900 font-bold mt-1 mb-2">Area rugs</p>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default FlooringOptions;
