import React, { useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import axios from "axios";
function RequestEstimate() {
  const [selectedDate, setSelectedDate] = useState(null);
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    phoneNumber: "",
    email: "",
    address: "",
    city: "",
    state: "",
    zip: "",
    date: null,
    products: {
      Carpet: false,
      CarpetTile: false,
      Laminate: false,
      LuxuryVinyl: false,
      Tile: false,
      Vinyl: false,
      Waterproof: false,
      Cork: false,
      AreaRugs: false,
      HardWood: false,
    },
    comment: "",
    keepMeUpdated: false,
  });

  const handleDateChange = (date) => {
    setSelectedDate(date);
    setFormData((prevFormData) => ({
      ...prevFormData,
      date: date,
    }));
  };

  const handleCheckboxChange = (checkboxName) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      products: {
        ...prevFormData.products,
        [checkboxName]: !prevFormData.products[checkboxName],
      },
    }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      //console.log(formData);
      const formresponse = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/form`,
        formData
      );
      alert(formresponse.data.data1);
      window.location.reload();
      window.scrollTo(0, 0);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  return (
    <div>
      <section className="p-4 container">
        <div className="text-center font-bold">
          <span style={{ fontSize: "25px" }}>Get in touch</span> <br />
          <small>
            {" "}
            MEET WITH ONE OF OUR SKILLED PROJECT SPECIALISTS TO GET STARTED
          </small>
        </div>
        <form className="row p-4" onSubmit={handleSubmit}>
          <div className="col-md-2"></div>
          <div className="col-md-8">
            <div class="row p-2">
              <div class="form-group col-md-6">
                <input
                  type="text"
                  className="form-control"
                  id="firstname"
                  placeholder="First Name"
                  value={formData.firstName}
                  onChange={(e) =>
                    setFormData({ ...formData, firstName: e.target.value })
                  }
                  required
                />
              </div>
              <div class="form-group col-md-6 mt-2">
                <input
                  type="text"
                  className="form-control"
                  id="lastname"
                  placeholder="last Name"
                  value={formData.lastName}
                  onChange={(e) =>
                    setFormData({ ...formData, lastName: e.target.value })
                  }
                  required
                />
              </div>
            </div>
            <div class="form-group p-2">
              <input
                type="text"
                className="form-control"
                id="phoneNumber"
                placeholder="Phone"
                value={formData.phoneNumber}
                onChange={(e) =>
                  setFormData({ ...formData, phoneNumber: e.target.value })
                }
                required
              />
            </div>
            <div class="form-group p-2">
              <input
                type="text"
                className="form-control"
                id="email"
                placeholder="Email"
                value={formData.email}
                onChange={(e) =>
                  setFormData({ ...formData, email: e.target.value })
                }
                required
              />
            </div>
            <div class="form-group p-2">
              <input
                type="text"
                className="form-control"
                id="address"
                placeholder="1234 Main St"
                value={formData.address}
                onChange={(e) =>
                  setFormData({ ...formData, address: e.target.value })
                }
                required
              />
            </div>
            <div class="row p-2">
              <div class="form-group col-md-6">
                <input
                  type="text"
                  className="form-control"
                  id="city"
                  placeholder="City"
                  value={formData.city}
                  onChange={(e) =>
                    setFormData({ ...formData, city: e.target.value })
                  }
                  required
                />
              </div>
              <div class="form-group col-md-4 mt-2">
                <input
                  type="text"
                  className="form-control"
                  id="state"
                  placeholder="State"
                  value={formData.state}
                  onChange={(e) =>
                    setFormData({ ...formData, state: e.target.value })
                  }
                  required
                />
              </div>
              <div class="form-group col-md-2 mt-2">
                <input
                  type="text"
                  className="form-control"
                  id="zip"
                  placeholder="Zip"
                  value={formData.zip}
                  onChange={(e) =>
                    setFormData({ ...formData, zip: e.target.value })
                  }
                  required
                />
              </div>
            </div>
            <div class="form-group p-2">
              <DatePicker
                selected={selectedDate}
                onChange={handleDateChange}
                placeholderText="Select a date"
                className="form-control p-2"
                dateFormat="MM/dd/yyyy"
                isClearable
              />
            </div>
            <div className="form-group row p-3">
              <label htmlFor="checkpoints" className="p-2">
                What products are you interested in?
              </label>
              {Object.keys(formData.products).map((productName) => (
                <div key={productName} className="form-check col-md-4">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    id={productName}
                    checked={formData.products[productName]}
                    onChange={() => handleCheckboxChange(productName)}
                  />
                  <label className="form-check-label" htmlFor={productName}>
                    {productName}
                  </label>
                </div>
              ))}
            </div>
            <div className="form-group p-2">
              <input
                type="text"
                className="form-control"
                id="comment"
                placeholder="Comment"
                value={formData.comment}
                onChange={(e) =>
                  setFormData({ ...formData, comment: e.target.value })
                }
                required
              />
            </div>
            <div className="form-group">
              <div className="form-check p-3">
                <input
                  className="form-check-input"
                  type="checkbox"
                  id="keepMeUpdated"
                  checked={formData.keepMeUpdated}
                  onChange={() =>
                    setFormData((prevFormData) => ({
                      ...prevFormData,
                      keepMeUpdated: !prevFormData.keepMeUpdated,
                    }))
                  }
                />
                <label className="form-check-label" htmlFor="keepMeUpdated">
                  Keep me up to date with exclusive offers.
                </label>
              </div>
            </div>
            <button
              type="submit"
              className=" rounded-lg h-7 w-full bg-blue-600 font-semibold text-white hover:bg-blue-800 transition"
            >
              Send
            </button>
          </div>
        </form>
      </section>
    </div>
  );
}

export default RequestEstimate;
