import React from 'react';
import 'bootstrap-icons/font/bootstrap-icons.css';
import Headers from '../Components/Header/Header'
import Footer from '../Components/Footer'
import brand1 from '../Components/finance.png';
import brand2 from '../Components/finance1.png';

function About() {
  return (
    <div>
      <div><Headers /> </div>
      <div className='text-center font-bold p-4'>
        <span style={{ fontSize: '35px' }}>Financing</span> <br />
        <small className='justify'>CALL US OR STOP IN TODAY TO EXPLORE ALL OF OUR AVAILABLE FINANCING OPTIONS</small>
      </div>
      <div class="grid gap-5 grid-cols-1 md:grid-cols-2 items-center justify-center ">
        <div class="flex items-center justify-center cursor-pointer" onClick={() => { window.open('https://etail.mysynchrony.com/eapply/eapply.action?uniqueId=0A96374331BD708109B284BEDF45A5396F5E0E04435551D3&client=Mohawk%20Carpet&intcmp=mohawk-flooring-internalapply', '_blank'); }}>

        <img src={brand1} alt="Los Angeles" />
        </div>
        <div class="flex items-center justify-center cursor-pointer" onClick={() => { window.open('https://www.synchrony.com/financing/home/partners?intcmp=na-homenetwork_cardholder-footer-data_reason-internal&uniqueId=A4A3EBF64AFDCFFC2134A5FA83B439C36152B7CA3C2EDF51&client=SynchronyHOME', '_blank'); }}>

        <img src={brand2} alt="Los Angeles" />
        </div>
          </div>
      <div><Footer /> </div>
    </div>
  )
}

export default About
