import React from 'react';
import { useNavigate } from 'react-router-dom';
import Header from '../Components/Header/Header';
import contractor from '../assets/Services/contractor.png';
import Footer from '../Components/Footer';
import ContactUs from "../Components/ContactUs";

function Services() {
  const navigate = useNavigate();
    return (
        <div>
            <div className="pb-6">
                <Header />
                </div>
    <div className="container mx-auto px-1 border-b-2 border-gray-600 pb-56">
      <h1 className="text-center font-bold text-4xl">SERVICES</h1>
      <p className="text-center text-gray-600 mb-10 mt-3 text-lg">
        SEE WHY WE’RE THE OROVILLE, CA AREA'S MOST TRUSTED SERVICE PROVIDER
      </p>

      <div className="flex flex-col md:flex-row items-center">
        <div className="w-full md:w-1/2 flex justify-center md:justify-end lg:justify-start">
          <img
            src={contractor}
            alt="CONTRACTOR"
            className="rounded-lg shadow-md md:mr-1 max-w-md mx-auto"
          />
        </div>

        <div className="md:w-1/2 md:pl-16 mt-10 md:mt-0">
          <ul className="list-disc text-gray-700 text-2xl space-y-3">
            <li>Carpet re-stretch</li>
            <li>Custom area rugs</li>
            <li>Flooring repairs</li>
            <li>Installation</li>
          </ul>
          <button onClick={() => { navigate('/contact'); }} className="mt-8 bg-blue-600 hover:bg-blue-800 text-white py-2 px-4 rounded">
            Get in touch
                        </button>
                        
                    </div>                  
                </div>
            </div>
            <div className="pt-10">
                            <ContactUs />
        </div>
        <div>
          <Footer />
        </div>
        </div>
        
  );
}

export default Services;